<template>
  <div>
    <div class="LineBreak"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.LineBreak {
  width: 50vw;
  height: 1px;
  margin: 15px auto;
  border-radius: 50px;
  background-color: #e6e6e6;
  border: 0px;
}
</style>
