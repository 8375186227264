var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{domProps:{"innerHTML":_vm._s(
      this.lang === 'pt_br'
        ? this.texts.title_projects.pt_br
        : this.texts.title_projects.eng
    )}}),_c('span',{staticClass:"container-info",domProps:{"innerHTML":_vm._s(
      this.lang === 'pt_br'
        ? this.texts.subtitle_projects.pt_br
        : this.texts.subtitle_projects.eng
    )}}),_c('div',{staticClass:"container-projects"},[_c('div',{ref:"slider",staticClass:"keen-slider"},_vm._l((_vm.projetos),function(projeto,i){return _c('div',{key:i,staticClass:"keen-slider__slide"},[_c('Project',{attrs:{"project":projeto,"lang":_vm.lang}})],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }