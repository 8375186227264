var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{domProps:{"innerHTML":_vm._s(
      this.lang === 'pt_br'
        ? this.texts.title_technologies.pt_br
        : this.texts.title_technologies.eng
    )}}),_c('span',{staticClass:"container-info",domProps:{"innerHTML":_vm._s(
      this.lang === 'pt_br'
        ? this.texts.subtitle_technologies.pt_br
        : this.texts.subtitle_technologies.eng
    )}}),_c('div',{staticClass:"container-technologies"},_vm._l((_vm.technologies),function(technology,i){return _c('Technology',{key:i,attrs:{"technologies":technology}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }