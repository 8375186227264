var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-trajectory",attrs:{"data-aos":"fade-up","data-aos-delay":"300"}},[_c('div',[_c('h1',{domProps:{"innerHTML":_vm._s(
        this.lang === 'pt_br'
          ? this.texts.title_trajectory.pt_br
          : this.texts.title_trajectory.eng
      )}}),_c('p',{domProps:{"innerHTML":_vm._s(
        this.lang === 'pt_br'
          ? this.texts.subtitle_trajectory.pt_br
          : this.texts.subtitle_trajectory.eng
      )}})]),_c('div',{staticClass:"timeline"},[_c('div',{staticClass:"timeline"},[_c('div',{staticClass:"step completed"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(
            this.lang === 'pt_br'
              ? this.texts.jobs_subtitle_trajectory[0].title.pt_br
              : this.texts.jobs_subtitle_trajectory[0].title.eng
          )}}),_c('div',{staticClass:"date"},[_c('span',{domProps:{"innerHTML":_vm._s(
              this.lang === 'pt_br'
                ? this.texts.jobs_subtitle_trajectory[0].text.pt_br
                : this.texts.jobs_subtitle_trajectory[0].text.eng
            )}}),_c('br'),_c('b',[_vm._v("03/02/2020")])])]),_c('div',{staticClass:"step completed"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(
            this.lang === 'pt_br'
              ? this.texts.jobs_subtitle_trajectory[1].title.pt_br
              : this.texts.jobs_subtitle_trajectory[1].title.eng
          )}}),_c('div',{staticClass:"date"},[_c('span',{domProps:{"innerHTML":_vm._s(
              this.lang === 'pt_br'
                ? this.texts.jobs_subtitle_trajectory[1].text.pt_br
                : this.texts.jobs_subtitle_trajectory[1].text.eng
            )}}),_c('br'),_c('b',[_vm._v("30/04/2021")])])]),_c('div',{staticClass:"step completed"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(
            this.lang === 'pt_br'
              ? this.texts.jobs_subtitle_trajectory[2].title.pt_br
              : this.texts.jobs_subtitle_trajectory[2].title.eng
          )}}),_c('div',{staticClass:"date"},[_c('span',{domProps:{"innerHTML":_vm._s(
              this.lang === 'pt_br'
                ? this.texts.jobs_subtitle_trajectory[2].text.pt_br
                : this.texts.jobs_subtitle_trajectory[2].text.eng
            )}}),_c('br'),_c('b',[_vm._v("16/02/2022")])])]),_c('div',{staticClass:"step current"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(
            this.lang === 'pt_br'
              ? this.texts.jobs_subtitle_trajectory[3].title.pt_br
              : this.texts.jobs_subtitle_trajectory[3].title.eng
          )}}),_c('div',{staticClass:"date"},[_c('span',{domProps:{"innerHTML":_vm._s(
              this.lang === 'pt_br'
                ? this.texts.jobs_subtitle_trajectory[3].text.pt_br
                : this.texts.jobs_subtitle_trajectory[3].text.eng
            )}}),_c('br')])]),_c('div',{staticClass:"step"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(
            this.lang === 'pt_br'
              ? this.texts.jobs_subtitle_trajectory[4].title.pt_br
              : this.texts.jobs_subtitle_trajectory[4].title.eng
          )}}),_c('div',{staticClass:"date"},[_c('span',{domProps:{"innerHTML":_vm._s(
              this.lang === 'pt_br'
                ? this.texts.jobs_subtitle_trajectory[4].text.pt_br
                : this.texts.jobs_subtitle_trajectory[4].text.eng
            )}}),_c('br')])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }