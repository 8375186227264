var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-email"},[_c('h1',{domProps:{"innerHTML":_vm._s(
      this.lang === 'pt_br'
        ? this.texts.send_mail_title.pt_br
        : this.texts.send_mail_title.eng
    )}}),_c('span',{domProps:{"innerHTML":_vm._s(
      this.lang === 'pt_br'
        ? this.texts.send_mail_span.pt_br
        : this.texts.send_mail_span.eng
    )}}),_c('br'),_c('div',{staticClass:"input-send"},[_c('form',{attrs:{"action":"https://formsubmit.co/06191976e626254db5e8061f28f63981","method":"POST"}},[_c('input',{attrs:{"type":"hidden","name":"_next","value":"https://alehsouza.dev/"}}),_c('input',{attrs:{"type":"hidden","name":"_template","value":"box"}}),_c('input',{attrs:{"type":"hidden","name":"_subject","value":"Portfólio - Mensagem nova"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name_message),expression:"name_message"}],attrs:{"placeholder":"John Doe","name":"name","required":""},domProps:{"value":(_vm.name_message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name_message=$event.target.value}}}),_c('input',{attrs:{"type":"hidden","name":"_autoresponse"},domProps:{"value":this.lang === 'pt_br'
            ? this.texts.send_mail_answer.pt_br
            : this.texts.send_mail_answer.eng}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email_message),expression:"email_message"}],attrs:{"placeholder":"john.doe@example.com","name":"email","required":""},domProps:{"value":(_vm.email_message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email_message=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text_message),expression:"text_message"}],attrs:{"placeholder":this.lang === 'pt_br'
            ? this.texts.send_mail_placeholder_msg.pt_br
            : this.texts.send_mail_placeholder_msg.eng,"name":"text","required":""},domProps:{"value":(_vm.text_message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.text_message=$event.target.value}}}),_c('button',{attrs:{"type":"submit","disabled":!_vm.name_message || !_vm.email_message || !_vm.text_message}},[_c('span',{staticClass:"fas fa-location-arrow"})])])]),_c('div',{staticClass:"social"},_vm._l((_vm.midias),function(midia,j){return _c('span',{key:j,staticClass:"midia"},[_c('a',{attrs:{"href":midia.link,"target":"_blank","alt":_vm.midias.alt}},[_c('span',{class:midia.icon})])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }